import {
  Checkbox,
  Col,
  FontWeight,
  Input,
  Row,
  Text,
  TextTypes
} from '@/common/components/atoms'
import { IFilterGroupedList, IFilterGroupedListOption } from '@/common/types'
import { ChangeEvent, FC, useMemo, useState } from 'react'
import { Color } from '@/packages/palette'
import { cloneDeep } from 'lodash'

interface IProps {
  filter: IFilterGroupedList
  selected: any
  onChange: (values: any) => void
}

const GroupedListFilter: FC<IProps> = (props) => {
  const { filter, selected, onChange } = props

  const [search, setSearch] = useState<string>('')

  const filteredGroups = useMemo(() => {
    if (!search) return filter.options

    const groups: IFilterGroupedListOption[] = []

    filter.options.forEach((group) => {
      const options = group.options.filter((item) =>
        item.title.toLowerCase().includes(search.toLowerCase())
      )

      if (options.length) {
        groups.push({ ...group, options })
      }
    })

    return groups
  }, [search, filter.options])

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value)
  }

  const handleSelectOption = (groupId: string, optionId: string) => {
    const newSelected: { [key: string]: string[] } = cloneDeep(selected) || {}

    // When there are selected options for this specific group
    if (Array.isArray(newSelected?.[groupId])) {
      newSelected[groupId] = newSelected[groupId].includes(optionId) // If this option selected
        ? newSelected[groupId].filter((item: string) => item !== optionId) // then remove
        : [...newSelected[groupId], optionId] // otherwise add it
    } else {
      // create an object for this group if it is not existing yet
      newSelected[groupId] = [optionId]
    }

    // Delete an key from value if there are nothing inside
    Object.entries(newSelected).forEach(([key, value]) => {
      if (!value?.length) {
        delete newSelected[key]
      }
    })

    // if there are any items send them otherwise clear the filter value
    onChange(Object.keys(newSelected).length ? newSelected : undefined)
  }

  return (
    <Col items="stretch" gap={8}>
      <div className="tw-px-8">
        <Input
          value={search}
          onChange={handleSearchChange}
          placeholder={filter.placeholder}
        />
      </div>

      <div>
        {filteredGroups.map((group) => (
          <Col items="stretch" key={group.id}>
            <div className="tw-p-9 background-color-gray100">
              <Text
                type={TextTypes.TEXT_XS}
                weight={FontWeight.SEMIBOLD}
                color={Color.gray600}
              >
                {group.title}
              </Text>
            </div>
            {group.options.map((option) => (
              <Row className="tw-px-9" key={option.id}>
                <Checkbox
                  label={option.title}
                  value={!!selected?.[group.id]?.includes?.(option.id)}
                  onChange={() => handleSelectOption(group.id, option.id)}
                />
              </Row>
            ))}
          </Col>
        ))}
      </div>
    </Col>
  )
}

export default GroupedListFilter
