import { createTheme, ThemeProvider } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { FC } from 'react'

import { UserContextProvider } from '@/common/contexts/UserContext'
import { RootRouter } from '@/router'
import { MobileNavigationContextProvider } from '@/common/contexts/MobileNavigationContext'

const theme = createTheme({
  typography: {
    fontFamily: ['Suisse', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(
      ','
    )
  }
})

const App: FC = () => (
  <ThemeProvider theme={theme}>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <UserContextProvider>
        <MobileNavigationContextProvider>
          <RootRouter />
        </MobileNavigationContextProvider>
      </UserContextProvider>
    </LocalizationProvider>
  </ThemeProvider>
)

export default App
