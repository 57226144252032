import { IconButton } from '@mui/material'
import clsx from 'clsx'
import { FC, MouseEvent, PropsWithChildren } from 'react'

import styles from './DarkButton.module.scss'

interface IProps {
  id: string
  disabled?: boolean
  className?: string
  onClick: (e: MouseEvent<HTMLButtonElement>) => void
}

const DarkButton: FC<IProps & PropsWithChildren> = (props) => {
  const { children, id, disabled, onClick, className } = props

  return (
    <IconButton
      disabled={disabled}
      aria-label={id}
      onClick={onClick}
      className={clsx(styles.darkButton, className)}
    >
      {children}
    </IconButton>
  )
}

export default DarkButton
