import { HeaderWithFooterTemplate } from '@/common/templates'
import { Button, Col } from '@/common/components/atoms'

import AddIcon from '@mui/icons-material/Add'
import {
  UsersSummary,
  DeleteUserModal,
  AddEditUserModal
} from '@/features/user-management'
import { IUsersSummary } from '@/features/user-management/types'
import { useMemo, useState } from 'react'
import { IUser } from '@/common/types'
import { EnterpriseRoles, SiteRoles, TableActions } from '@/common/enums'
import {
  getDateDifference,
  groupingHelper,
  tableFilters,
  tableGroupByOptions,
  userManagementColumns
} from '@/features/user-management/utils'
import { Table } from '@/common/components/organisms'

import styles from './UserManagement.module.scss'
import { useSearchParams } from 'react-router-dom'
import dayjs from 'dayjs'
import { useModal } from '@/common/hooks/useModal'

const users: IUser[] = [
  {
    id: '1',
    name: 'Misha Kliuvak',
    email: 'mishakliuvak10@gmail.com',
    lastActivity: new Date(),
    enterpriseRoles: [EnterpriseRoles.Admin],
    siteRoles: [SiteRoles.Gate, SiteRoles.Admin],
    availableSites: [
      { id: 'site1', name: 'Site 1' },
      { id: 'site2', name: 'Site 2' },
      { id: 'site3', name: 'Site 3' }
    ],
    siteRolesList: [
      { id: '1', site: 'site1', roles: [SiteRoles.Gate] },
      { id: '2', site: 'site2', roles: [SiteRoles.Gate] },
      { id: '3', site: 'site3', roles: [SiteRoles.Admin] }
    ]
  },
  {
    id: '2',
    name: 'Xavier Xicay',
    email: 'xavier@xicay.com',
    lastActivity: undefined,
    siteRoles: [SiteRoles.Gate],
    enterpriseRoles: [],
    availableSites: [{ id: 'site1', name: 'Site 1' }],
    siteRolesList: [{ id: '1', site: 'site1', roles: [SiteRoles.Gate] }]
  },
  {
    id: '4',
    name: 'Lazy Misha',
    email: 'lazy@gmail.com',
    lastActivity: new Date('2023-12-17'),
    siteRoles: [],
    availableSites: [],
    enterpriseRoles: []
  },
  {
    id: '3',
    name: 'Chris Brumett',
    email: 'chris.brumett@gmail.com',
    lastActivity: undefined,
    siteRoles: [],
    availableSites: [],
    enterpriseRoles: [EnterpriseRoles.ISRManager]
  }
]

const UserManagementTemplate = () => {
  const [searchParams] = useSearchParams()
  const urlFilter = searchParams.get('filter')

  const {
    visible,
    openModal,
    closeModal: close
  } = useModal<'delete' | 'user'>()

  const [data, setData] = useState(() => users)
  const [selectedItem, setSelectedItem] = useState<any>()

  const closeModal = () => {
    close()
    setSelectedItem(undefined)
  }

  const defaultFilters = useMemo(() => {
    if (urlFilter === 'siteAdmin')
      return { role: { siteRoles: [SiteRoles.Admin] } }

    if (urlFilter === 'enterpriseAdmin')
      return { role: { enterpriseRoles: [EnterpriseRoles.Admin] } }

    if (urlFilter === 'inactive') {
      const today = dayjs()
      const endDate = today.subtract(90, 'day')

      return { lastActivity: { endDate } }
    }

    return undefined
  }, [urlFilter])

  const summary: IUsersSummary = useMemo(() => {
    const values: IUsersSummary = {
      total: data.length,
      siteAdmins: 0,
      enterpriseAdmins: 0,
      inactive: 0
    }

    data.forEach((item: IUser) => {
      if (item.enterpriseRoles.includes(EnterpriseRoles.Admin)) {
        values.enterpriseAdmins += 1
      }

      if (item.siteRoles.includes(SiteRoles.Admin)) {
        values.siteAdmins += 1
      }

      if (
        item.lastActivity &&
        getDateDifference(new Date(), item.lastActivity) >= 90
      ) {
        values.inactive += 1
      }
    })

    return values
  }, [data])

  const onDelete = () => {
    setData((prev) => prev.filter((item) => item.id !== selectedItem.id))
    closeModal()
  }

  const handleAction = (action: TableActions, item: any) => {
    switch (action) {
      case TableActions.Delete: {
        openModal('delete')
        setSelectedItem(item)
        break
      }

      case TableActions.Edit:
      case TableActions.RowClick: {
        openModal('user')
        setSelectedItem(item)
        break
      }

      default: {
        break
      }
    }
  }

  const CreateUserButton = (
    <Button
      type="primary"
      startIcon={<AddIcon />}
      onClick={() => openModal('user')}
    >
      New User
    </Button>
  )

  return (
    <HeaderWithFooterTemplate headerActions={CreateUserButton}>
      {visible === 'delete' && (
        <DeleteUserModal
          onDelete={onDelete}
          name={selectedItem.name}
          closeModal={closeModal}
        />
      )}

      {visible === 'user' && (
        <AddEditUserModal
          selectedItem={selectedItem}
          editMode={!!selectedItem}
          closeModal={closeModal}
        />
      )}

      <Col items="stretch" className="tw-h-full">
        <div className={styles.mobileButtonWrapper}>{CreateUserButton}</div>

        <UsersSummary summary={summary} />

        <div className="tw-flex-1 tw-flex">
          <Table
            clickable
            name="users"
            idAccessor="id"
            rows={data}
            searchFields={['name']}
            handleAction={handleAction}
            defaultFilters={defaultFilters}
            groupingHelper={groupingHelper}
            filters={tableFilters}
            isRowInactive={(row) =>
              row.lastActivity &&
              getDateDifference(new Date(), row.lastActivity) >= 90
            }
            groupByOptions={tableGroupByOptions}
            columns={userManagementColumns}
          />
        </div>
      </Col>
    </HeaderWithFooterTemplate>
  )
}

export default UserManagementTemplate
