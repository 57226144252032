import { EnterpriseRoles, SiteRoles, TableFilterType } from '@/common/enums'
import { IFilterItem } from '@/common/types'

const tableFilters: IFilterItem[] = [
  {
    id: 'role',
    name: 'Role',
    type: TableFilterType.GroupedList,
    placeholder: 'Search Roles',
    options: [
      {
        id: 'enterpriseRoles',
        title: 'Enterprise',
        options: [
          { id: EnterpriseRoles.Admin, title: 'Admin' },
          { id: EnterpriseRoles.ISRManager, title: 'ISR Manager' }
        ]
      },
      {
        id: 'siteRoles',
        title: 'Site',
        options: [
          { id: SiteRoles.Admin, title: 'Admin' },
          { id: SiteRoles.ISRManager, title: 'ISR Manager' },
          { id: SiteRoles.Gate, title: 'Gate' },
          { id: SiteRoles.ViewOnly, title: 'View Only' }
        ]
      }
    ]
  },
  {
    id: 'lastActivity',
    name: 'Last Active',
    type: TableFilterType.DateRange,
    extraOptions: [
      {
        id: 'no',
        title: 'Never Logged In',
        isValid: (row) => !row.lastActivity
      }
    ]
  },
  {
    id: 'availableSites',
    name: 'Site',
    type: TableFilterType.List,
    placeholder: 'Search Sites',
    options: [
      {
        id: 'site1',
        title: 'Site 1'
      },
      {
        id: 'site2',
        title: 'Site 2'
      },
      {
        id: 'site3',
        title: 'Site 3'
      }
    ]
  }
]

export default tableFilters
