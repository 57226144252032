import React, { FC } from 'react'
import { Route, Routes } from 'react-router-dom'

import { MainLayout } from '@/common/templates'
import ROUTES from '@/router/routes'
import { TemporaryScreen } from '@/features/temporary'
import { UserManagementTemplate } from '@/features/user-management'
import { ProfileTemplate } from '@/features/profile'
import { SuperAdminPortalTemplate } from '@/features/super-admin-portal'

const RootRouter: FC = () => (
  <Routes>
    <Route element={<MainLayout />}>
      <Route path={ROUTES.HOME} element={<TemporaryScreen />} />
      <Route path={ROUTES.GATE} element={<TemporaryScreen />} />
      <Route path={ROUTES.GATE_TRANSACTIONS} element={<TemporaryScreen />} />
      <Route path={ROUTES.GATE_QUEUE} element={<TemporaryScreen />} />
      <Route path={ROUTES.ISR} element={<TemporaryScreen />} />
      <Route path={ROUTES.ADMIN_VIDEO} element={<TemporaryScreen />} />
      <Route path={ROUTES.ADMIN_MISMATCHES} element={<TemporaryScreen />} />
      <Route path={ROUTES.YARD_CARGO_ASSET} element={<TemporaryScreen />} />
      <Route path={ROUTES.YARD_POWER_UNITS} element={<TemporaryScreen />} />
      <Route path={ROUTES.USERS} element={<UserManagementTemplate />} />
      <Route path={ROUTES.PROFILE} element={<ProfileTemplate />} />
      <Route
        path={ROUTES.SUPER_ADMIN_PORTAL}
        element={<SuperAdminPortalTemplate />}
      />
    </Route>
  </Routes>
)

export default RootRouter
