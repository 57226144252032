import './DateInput.scss'
import styles from '@/common/components/atoms/Input/Input.module.scss'
import clsx from 'clsx'
import { DatePicker } from '@mui/x-date-pickers'
import React, { FC, useState } from 'react'
import { Dayjs } from 'dayjs'

interface IProps {
  value?: Dayjs
  onChange: (value: Dayjs | null) => void
  placeholder?: string
  min?: Dayjs
  max?: Dayjs
}

const DateInput: FC<IProps> = (props) => {
  const { value, min, max, onChange, placeholder } = props

  const [open, setOpen] = useState(false)

  return (
    <DatePicker
      value={value || null}
      onChange={onChange}
      open={open}
      minDate={min}
      maxDate={max}
      onClose={() => setOpen(false)}
      slotProps={{
        textField: {
          placeholder,
          variant: 'standard',
          onClick: (e) => {
            e.stopPropagation()
            setOpen(() => !open)
          },
          classes: { root: clsx(styles.root, 'tw-w-115') },
          InputProps: {
            endAdornment: null,
            classes: { root: clsx(styles.input) }
          },
          InputLabelProps: {
            classes: {
              root: styles.label
            }
          }
        }
      }}
    />
  )
}

export default DateInput
