import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined'
import { FC } from 'react'
import { useWindowSize } from 'usehooks-ts'

import {
  Avatar,
  FontWeight,
  Row,
  Text,
  TextTypes
} from '@/common/components/atoms'
import { PortalTypes } from '@/common/enums'
import { Color } from '@/packages/palette'

import styles from './PortalDropdownItem.module.scss'

interface IProps {
  type: PortalTypes
  text: string
  index?: number
}

const sitePortalsColors = [
  { color: Color.purple400, textColor: Color.gray0 },
  { color: Color.green400, textColor: Color.gray0 },
  { color: Color.blue400, textColor: Color.gray0 },
  { color: Color.yellow400, textColor: Color.gray800 },
  { color: Color.gray400, textColor: Color.gray800 },
  { color: Color.red400, textColor: Color.gray0 },
  { color: Color.blue200, textColor: Color.gray800 },
  { color: Color.purple200, textColor: Color.gray800 }
]

const PortalDropdownItem: FC<IProps> = (props) => {
  const { type, index, text } = props

  const { width } = useWindowSize()
  const isSmallView = !!width && width <= 744

  return (
    <Row items="center" gap={4} className={styles.itemWrapper}>
      {type === PortalTypes.Enterprise ? (
        <BusinessOutlinedIcon className={styles.enterpriseSvg} />
      ) : (
        <Avatar
          color={sitePortalsColors[index as number].color}
          textColor={sitePortalsColors[index as number].textColor}
          name={text}
          size={isSmallView ? 'md' : 'sm'}
        />
      )}

      <Text
        type={TextTypes.TEXT_SM}
        color={Color.gray700}
        weight={FontWeight.SEMIBOLD}
      >
        {text}
      </Text>
    </Row>
  )
}

export default PortalDropdownItem
