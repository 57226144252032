import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material'
import React, { FC, useEffect, useMemo, useState } from 'react'

import styles from './Table.module.scss'
import { Col, Text, TextTypes } from '@/common/components/atoms'
import {
  IFilterItem,
  IFilterValues,
  IGroupByItem,
  ITableColumn
} from '@/common/types'
import {
  TableFilters,
  TableRow as CustomTableRow
} from '@/common/components/molecules'
import { Color } from '@/packages/palette'
import { TableContextProvider } from '@/common/contexts/TableContext'
import { TableActions } from '@/common/enums'
import { filter, search } from '@/common/utils'
import clsx from 'clsx'

interface IProps {
  name: string
  columns: (groupBy: string | undefined) => ITableColumn[]
  rows: any[]
  idAccessor: string
  clickable?: boolean
  defaultFilters?: IFilterValues
  filters?: IFilterItem[]
  searchFields?: string[]
  groupByOptions?: IGroupByItem[]
  isRowInactive?: (row: any) => boolean
  handleAction?: (action: TableActions, row: any) => void
  groupingHelper?: (groupBy: string, rows: any[]) => any[]
}

const CustomTable: FC<IProps> = (props) => {
  const {
    name,
    idAccessor,
    filters,
    groupByOptions,
    columns,
    isRowInactive,
    searchFields,
    clickable,
    handleAction,
    defaultFilters,
    groupingHelper,
    rows = []
  } = props

  const [searchValue, setSearchValue] = useState<string | undefined>()
  const [groupBy, setGroupBy] = useState<string | undefined>()
  const [filterValues, setFilterValues] = useState<IFilterValues>({})

  const withSearch = !!searchFields
  const withFilters = withSearch || !!groupByOptions || !!filters

  const memoRows: any[] = useMemo(() => {
    const withSearchApplied = search(searchFields, searchValue, rows)
    const filtered = filters?.length
      ? filter(filters, filterValues, withSearchApplied)
      : withSearchApplied

    if (groupBy) {
      return groupingHelper?.(groupBy, filtered) || []
    }

    return filtered
  }, [rows, groupBy, searchValue, filterValues])

  const memoCols: ITableColumn[] = useMemo(() => columns(groupBy), [groupBy])

  const providerValue = useMemo(
    () => ({
      searchValue,
      filterValues,
      groupBy,

      filters,
      groupByOptions,

      isRowInactive,
      setFilterValues,
      handleAction,
      onSearchChange: setSearchValue,
      onGroupByChange: setGroupBy
    }),
    [
      searchValue,
      filterValues,
      groupBy,
      filters,
      groupByOptions,
      handleAction,
      isRowInactive
    ]
  )

  const clearFilters = () => {
    setSearchValue('')
    setFilterValues({})
  }

  useEffect(() => {
    if (defaultFilters) {
      setFilterValues(defaultFilters)
    }
  }, [defaultFilters])

  return (
    <TableContextProvider value={providerValue}>
      <Col items="stretch" className="tw-self-stretch tw-w-full">
        {withFilters && <TableFilters withSearch={withSearch} />}

        {memoRows.length ? (
          <div className={styles.tableWrapper}>
            <TableContainer>
              <Table
                size="medium"
                aria-labelledby={name}
                classes={{
                  root: clsx(styles.table, withFilters && styles.borderTop)
                }}
              >
                <TableHead>
                  <TableRow>
                    {memoCols.map((column) => (
                      <TableCell key={column.id} variant="head" align="left">
                        {column.title}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {memoRows.map((row) => (
                    <CustomTableRow
                      key={row[idAccessor]}
                      row={row}
                      columns={memoCols}
                      idAccessor={idAccessor}
                      clickable={clickable}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        ) : (
          <Col items="center" justify="center" gap={8} className="tw-flex-1">
            <Text type={TextTypes.TEXT_LG} color={Color.gray700}>
              No results found.
            </Text>
            <Text type={TextTypes.TEXT_MD} color={Color.gray600}>
              Try searching or filtering by different criteria.{' '}
              <span
                className="color-green500 tw-cursor-pointer"
                onClick={clearFilters}
              >
                Clear Filters
              </span>
            </Text>
          </Col>
        )}
      </Col>
    </TableContextProvider>
  )
}

export default CustomTable
