import { IModalWithCloseFn } from '@/common/types'
import { FC, PropsWithChildren, ReactNode } from 'react'
import { Box, Modal, Slide } from '@mui/material'

import styles from './Modal.module.scss'
import {
  Button,
  FontWeight,
  Row,
  Text,
  TextTypes
} from '@/common/components/atoms'
import { Color } from '@/packages/palette'
import CloseIcon from '@mui/icons-material/Close'
import clsx from 'clsx'

interface IProps extends IModalWithCloseFn {
  title: string
  formMode?: boolean
  footer?: ReactNode
  className?: string
  placement?: 'left' | 'right' | 'center'
}

const CustomModal: FC<PropsWithChildren & IProps> = (props) => {
  const {
    formMode,
    closeModal,
    className,
    placement = 'center',
    footer,
    title,
    children
  } = props

  const Wrapper = formMode ? 'form' : 'div'

  return (
    <Modal
      open
      onClose={closeModal}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Slide in direction="left">
        <Wrapper className={clsx(styles.box, styles[placement], className)}>
          <Row
            items="center"
            gap={10}
            justify="between"
            className={styles.header}
          >
            <Text
              weight={FontWeight.SEMIBOLD}
              type={TextTypes.TEXT_XL}
              color={Color.gray700}
            >
              {title}
            </Text>

            <div
              className={styles.closeButton}
              tabIndex={0}
              onClick={closeModal}
            >
              <CloseIcon />
            </div>
          </Row>

          <div className="tw-p-16 tw-pb-24 tw-overflow-y-auto">{children}</div>

          <Row justify="end" items="stretch" gap={8} className={styles.footer}>
            <Button type="outlined" onClick={closeModal}>
              Close
            </Button>

            {footer}
          </Row>
        </Wrapper>
      </Slide>
    </Modal>
  )
}

export default CustomModal
