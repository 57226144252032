import { IFilterDateRange } from '@/common/types'
import React, { FC } from 'react'
import {
  Col,
  DateInput,
  FontWeight,
  Row,
  Text,
  TextTypes
} from '@/common/components/atoms'
import { Dayjs } from 'dayjs'
import clsx from 'clsx'
import { Color } from '@/packages/palette'

interface IDateValue {
  startDate?: Dayjs
  endDate?: Dayjs
}

interface IProps {
  filter: IFilterDateRange
  value: string | IDateValue | undefined
  onChange: (value: any, close?: boolean) => void
}

const DateRangeFilter: FC<IProps> = (props) => {
  const { filter, value, onChange } = props

  const dateValue = value as IDateValue
  const optionValue = value as string

  const onUpdateValue = (
    id: 'endDate' | 'startDate' | 'option',
    newValue: Dayjs | null | string
  ) => {
    if (id === 'option') {
      onChange(newValue === value ? undefined : newValue, true)
      return
    }

    const updatedDates = {
      startDate: id === 'startDate' ? newValue : dateValue?.startDate,
      endDate: id === 'endDate' ? newValue : dateValue?.endDate
    }

    if (!updatedDates.startDate) {
      delete updatedDates.startDate
    }

    if (!updatedDates.endDate) {
      delete updatedDates.endDate
    }

    onChange(Object.keys(updatedDates).length ? updatedDates : undefined)
  }

  return (
    <Col items="stretch" gap={6}>
      <Row items="center" justify="between" gap={8} className="tw-px-8">
        <div>
          <DateInput
            max={dateValue?.endDate}
            value={dateValue?.startDate}
            onChange={(v) => onUpdateValue('startDate', v)}
            placeholder="Start Date"
          />
        </div>
        -
        <div>
          <DateInput
            min={dateValue?.startDate}
            value={dateValue?.endDate}
            onChange={(v) => onUpdateValue('endDate', v)}
            placeholder="End Date"
          />
        </div>
      </Row>

      {Array.isArray(filter.extraOptions) && (
        <Col>
          {filter.extraOptions.map((option) => (
            <div
              tabIndex={0}
              key={option.id}
              onClick={() => onUpdateValue('option', option.id)}
              className={clsx(
                'tw-py-8 tw-px-16 tw-cursor-pointer hover-background-color-gray100',
                optionValue === option.id && 'background-color-gray200'
              )}
            >
              <Text
                type={TextTypes.TEXT_MD}
                color={Color.gray800}
                weight={
                  optionValue === option.id
                    ? FontWeight.SEMIBOLD
                    : FontWeight.REGULAR
                }
              >
                {option.title}
              </Text>
            </div>
          ))}
        </Col>
      )}
    </Col>
  )
}

export default DateRangeFilter
