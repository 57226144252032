import { EnterpriseRoles, SiteRoles } from '@/common/enums'

const roleNamesMapper = (rolesList: SiteRoles[] | EnterpriseRoles[]) => {
  const roles = {
    [SiteRoles.Admin]: 'Admin',
    [SiteRoles.ISRManager]: 'ISR Manager',
    [SiteRoles.Gate]: 'Gate',
    [SiteRoles.ViewOnly]: 'View Only',
    [EnterpriseRoles.Admin]: 'Admin',
    [EnterpriseRoles.ISRManager]: 'ISR Manager'
  }

  return rolesList.length
    ? rolesList.map((role) => roles[role]).join(', ')
    : '-'
}

export default roleNamesMapper
