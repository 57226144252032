import { IAction } from '@/common/types'

import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { Row, Text, TextTypes } from '@/common/components/atoms'
import React, { FC, useRef, useState } from 'react'

import styles from './ActionsDropdown.module.scss'
import { Popover } from '@mui/material'
import clsx from 'clsx'
import { Color } from '@/packages/palette'

interface IProps {
  actions: IAction[]
  onClick: (action: string) => void
}

const ActionsDropdown: FC<IProps> = (props) => {
  const { actions, onClick } = props

  const containerRef = useRef<any>()
  const [open, setOpen] = useState(false)

  const toggle = () => setOpen(!open)

  return (
    <div className={styles.actions}>
      <Row
        tabIndex={0}
        ref={containerRef}
        items="center"
        justify="end"
        clickable
        onClick={(e) => {
          e.stopPropagation()
          toggle()
        }}
        className={styles.actionBtn}
      >
        <MoreHorizIcon />
      </Row>

      <Popover
        disablePortal
        id="actions-popover"
        open={open}
        anchorEl={containerRef.current}
        onClose={toggle}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        classes={{ paper: styles.actionsDropdown }}
      >
        <div>
          {actions.map((action) => (
            <div
              id={action.id}
              key={action.id}
              tabIndex={0}
              className={clsx(styles.option)}
              onClick={(e) => {
                e.stopPropagation()
                onClick(action.id)
                setOpen(false)
              }}
              // onKeyDown={(e) => handleKeyDown(e, item)}
            >
              <Text type={TextTypes.TEXT_MD} color={Color.gray800}>
                {action.label}
              </Text>
            </div>
          ))}
        </div>
      </Popover>
    </div>
  )
}

export default ActionsDropdown
