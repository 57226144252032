import './Button.scss'

import Button from '@mui/material/Button'
import {
  PropsWithChildren,
  ReactNode,
  MouseEvent,
  ForwardRefRenderFunction,
  forwardRef
} from 'react'
import clsx from 'clsx'

interface IProps extends PropsWithChildren {
  type?: 'primary' | 'secondary' | 'outlined' | 'danger' | 'text'
  action?: 'button' | 'submit'
  disabled?: boolean
  keepFocus?: boolean
  startIcon?: ReactNode
  endIcon?: ReactNode
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void
  className?: string
}

type TVariant = 'outlined' | 'contained' | 'text' | undefined
type TColor = 'primary' | 'secondary' | 'info' | 'error' | undefined

const CustomButton: ForwardRefRenderFunction<HTMLButtonElement, IProps> = (
  props,
  ref
) => {
  const {
    action,
    type = 'primary',
    onClick,
    keepFocus,
    startIcon,
    endIcon,
    disabled,
    children,
    className
  } = props

  const variant = {
    primary: 'contained',
    secondary: 'contained',
    outlined: 'outlined',
    danger: 'contained',
    text: 'text'
  }

  const color = {
    primary: 'primary',
    secondary: 'secondary',
    outlined: 'info',
    danger: 'error',
    text: 'primary'
  }

  return (
    <Button
      ref={ref}
      type={action}
      disableRipple
      onClick={onClick}
      startIcon={startIcon}
      endIcon={endIcon}
      disabled={disabled}
      color={color[type] as TColor}
      variant={variant[type] as TVariant}
      disableElevation
      className={clsx('button', keepFocus && 'focused', className)}
    >
      {children}
    </Button>
  )
}

export default forwardRef(CustomButton)
