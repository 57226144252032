import { IUsersSummary } from '@/features/user-management/types'
import React, { FC, useState } from 'react'
import FilterListIcon from '@mui/icons-material/FilterList'

import styles from './UsersSummary.module.scss'
import {
  Col,
  FontWeight,
  Row,
  Text,
  TextTypes
} from '@/common/components/atoms'
import { Color } from '@/packages/palette'
import { useSearchParams } from 'react-router-dom'

interface IProps {
  summary: IUsersSummary
}

const UsersSummary: FC<IProps> = (props) => {
  const { summary } = props
  const { total, siteAdmins, enterpriseAdmins, inactive } = summary

  const [searchParams, setSearchParams] = useSearchParams()

  const applyFilter = (value: string) => {
    setSearchParams({ filter: value })
  }

  const renderItem = (title: string, value: number, filter?: string) => (
    <Row
      items="center"
      gap={20}
      justify="between"
      clickable={!!filter}
      onClick={() => filter && applyFilter(filter)}
    >
      <Col gap={4}>
        <Text
          type={TextTypes.TEXT_XS}
          color={Color.gray600}
          className="tw-whitespace-nowrap"
        >
          {title}
        </Text>

        <Text
          type={TextTypes.DISPLAY_XS}
          color={Color.gray700}
          weight={FontWeight.BOLD}
        >
          {value}
        </Text>
      </Col>

      <FilterListIcon width={16} height={16} className={styles.filterIcon} />
    </Row>
  )

  return (
    <div className={styles.summaryContainer}>
      {renderItem('Total Users', total)}
      {renderItem('Enterprise Admins', enterpriseAdmins, 'enterpriseAdmin')}
      {renderItem('Site Admins', siteAdmins, 'siteAdmin')}
      {renderItem('90 Days+ Inactivity', inactive, 'inactive')}
    </div>
  )
}

export default UsersSummary
