import { FormControlLabel, Switch } from '@mui/material'
import { FC } from 'react'

import styles from './Switch.module.scss'
import clsx from 'clsx'

interface IProps {
  value: boolean
  onChange: (value: boolean) => void
  label?: string
  disabled?: boolean
}

const CustomSwitch: FC<IProps> = (props) => {
  const { value, onChange, label, disabled } = props

  const handleChange = () => {
    onChange(!value)
  }

  const Element = (
    <Switch
      checked={value}
      disabled={disabled}
      onChange={handleChange}
      classes={{
        thumb: styles.thumb,
        checked: styles.active,
        track: clsx(styles.track, value && styles.active),
        disabled: styles.disabled
      }}
    />
  )

  return label ? (
    <FormControlLabel
      classes={{ label: clsx(styles.label, disabled && styles.disabled) }}
      control={Element}
      label={label}
    />
  ) : (
    Element
  )
}

export default CustomSwitch
