const ROUTES = {
  HOME: '/',
  GATE: '/gate',
  GATE_QUEUE: '/gate/queue',
  GATE_TRANSACTIONS: '/gate/transactions',
  YARD_POWER_UNITS: '/yard/power-units',
  YARD_CARGO_ASSET: '/yard/cargo-asset',
  ISR: '/isr',
  ADMIN_MISMATCHES: '/administration/mismatches',
  ADMIN_VIDEO: '/administration/video',
  USERS: '/users',
  PROFILE: '/profile',
  SUPER_ADMIN_PORTAL: '/admin-portal'
}

export default ROUTES
