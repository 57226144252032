import * as yup from 'yup'

const UserSchema = yup.object({
  firstName: yup
    .string()
    .trim()
    .max(20, 'Max length is 20')
    .required('First Name is required'),
  lastName: yup
    .string()
    .trim()
    .max(20, 'Max length is 20')
    .required('Last Name is required'),
  email: yup
    .string()
    .matches(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/, 'Invalid email')
    .required('Email is required')
})

export default UserSchema
