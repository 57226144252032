export enum PortalTypes {
  Site = 'site-portal',
  Enterprise = 'enterprise'
}

export enum SiteRoles {
  Admin = 'siteAdmin',
  ISRManager = 'siteIsrManager',
  Gate = 'gate',
  ViewOnly = 'viewOnly'
}

export enum EnterpriseRoles {
  Admin = 'enterpriseAdmin',
  ISRManager = 'enterpriseIsrManager'
}

export enum Brands {
  Terminal = 'Terminal',
  Ryder = 'Ryder',
  NFI = 'NFI'
}

export enum TableColumnType {
  Text = 'text',
  Date = 'date',
  Actions = 'actions',
  Link = 'link'
}

export enum TableFilterType {
  List = 'list',
  GroupedList = 'groupedList',
  DateRange = 'dateRange'
}

export enum TableActions {
  Delete = 'delete',
  Edit = 'edit',
  RowClick = 'rowClick'
}
