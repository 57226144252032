export enum Color {
  blue100 = 'color-blue100',
  blue200 = 'color-blue200',
  blue300 = 'color-blue300',
  blue400 = 'color-blue400',
  blue500 = 'color-blue500',
  blue600 = 'color-blue600',

  gray0 = 'color-gray0',
  gray100 = 'color-gray100',
  gray200 = 'color-gray200',
  gray300 = 'color-gray300',
  gray400 = 'color-gray400',
  gray500 = 'color-gray500',
  gray600 = 'color-gray600',
  gray700 = 'color-gray700',
  gray800 = 'color-gray800',

  green100 = 'color-green100',
  green200 = 'color-green200',
  green300 = 'color-green300',
  green400 = 'color-green400',
  green500 = 'color-green500',
  green550 = 'color-green550',
  green600 = 'color-green600',
  green650 = 'color-green650',
  green700 = 'color-green700',

  red100 = 'color-red100',
  red200 = 'color-red200',
  red300 = 'color-red300',
  red400 = 'color-red400',
  red500 = 'color-red500',
  red600 = 'color-red600',

  yellow100 = 'color-yellow100',
  yellow200 = 'color-yellow200',
  yellow300 = 'color-yellow300',
  yellow400 = 'color-yellow400',
  yellow500 = 'color-yellow500',

  purple100 = 'color-purple100',
  purple200 = 'color-purple200',
  purple300 = 'color-purple300',
  purple400 = 'color-purple400',
  purple500 = 'color-purple500',
  purple600 = 'color-purple600'
}
