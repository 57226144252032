import React, { FC, PropsWithChildren, useContext } from 'react'
import { IFilterItem, IFilterValues, IGroupByItem } from '@/common/types'
import { TableActions } from '@/common/enums'

interface TableContextValue {
  filters?: IFilterItem[]
  filterValues: IFilterValues
  setFilterValues: (value: IFilterValues) => void
  groupByOptions?: IGroupByItem[]
  searchValue?: string | undefined
  onSearchChange: (value: string | undefined) => void
  groupBy: string | undefined
  isRowInactive?: (row: any) => boolean
  handleAction?: (action: TableActions, row: any) => void
  onGroupByChange: (value: string | undefined) => void
}

const TableContext = React.createContext<TableContextValue>(
  {} as TableContextValue
)

const TableContextProvider: FC<
  PropsWithChildren<{ value: TableContextValue }>
> = (props) => {
  const { children, value } = props

  return <TableContext.Provider value={value}>{children}</TableContext.Provider>
}

const useTableContext = () => useContext(TableContext)

export { TableContextProvider, useTableContext }
