import {
  Button,
  Chip,
  DateRangeFilter,
  GroupedListFilter,
  ListFilter,
  Portal,
  Row,
  Text,
  TextTypes
} from '@/common/components/atoms'
import { FilterValues } from '@/common/components/molecules'
import FilterListIcon from '@mui/icons-material/FilterList'
import {
  IFilterDateRange,
  IFilterGroupedList,
  IFilterItem,
  IFilterList
} from '@/common/types'
import {
  FC,
  KeyboardEvent,
  ReactNode,
  useRef,
  useState,
  MouseEvent
} from 'react'
import { Popover, Slide } from '@mui/material'

import clsx from 'clsx'
import { Color } from '@/packages/palette'

import styles from './FilterDropdown.module.scss'
import { TableFilterType } from '@/common/enums'
import { cloneDeep, isEqual } from 'lodash'
import { useTableContext } from '@/common/contexts/TableContext'
import { useSearchParams } from 'react-router-dom'
import { useWindowSize } from 'usehooks-ts'

const FilterDropdown: FC = (props) => {
  const {
    filters,
    filterValues: values,
    setFilterValues: onUpdate
  } = useTableContext()

  const containerRef = useRef<any>()
  const [, setSearchParams] = useSearchParams()

  const { width } = useWindowSize()
  const isSmallView = !!width && width <= 744

  const [open, setOpen] = useState(false)
  const [selectedFilter, setSelectedFilter] = useState<
    IFilterItem | undefined
  >()

  const isFilterApplied = Object.values(values || {}).some(
    (filterValue) => !!filterValue
  )

  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    containerRef.current = e.currentTarget

    setOpen(!open)

    if (open) {
      setSelectedFilter(undefined)
    }
  }

  const toggleSpecificFilter = (
    e: MouseEvent<HTMLSpanElement>,
    filter: IFilterItem
  ) => {
    containerRef.current = e.target

    setOpen(true)
    setSelectedFilter(filter)
  }

  const handleClose = () => {
    setOpen(false)

    setTimeout(() => {
      setSelectedFilter(undefined)
    }, 200)
  }

  const handleOptionClick = (filter: IFilterItem) => {
    setSelectedFilter(filter)
  }

  const handleKeyDown = (event: KeyboardEvent, item: IFilterItem) => {
    if (event.key === 'Enter') {
      handleOptionClick(item)
    }
  }

  const onChange = (filterId: string, value: any, close?: boolean) => {
    onUpdate({ ...cloneDeep(values), [filterId]: value })

    if (close) {
      handleClose()
    }
  }

  const clearFilters = () => {
    onUpdate({})
    setSearchParams({})
  }

  const FirstStep = (
    <div>
      {filters?.map((item) => (
        <div
          id={item.id}
          key={item.id}
          tabIndex={0}
          className={clsx(styles.filterOption)}
          onClick={() => handleOptionClick(item)}
          onKeyDown={(e) => handleKeyDown(e, item)}
        >
          <Text type={TextTypes.TEXT_MD} color={Color.gray800}>
            {item.name}
          </Text>
        </div>
      ))}
    </div>
  )

  const renderSecondStep = () => {
    if (!selectedFilter) return null

    let Component: ReactNode

    switch (selectedFilter.type) {
      case TableFilterType.DateRange:
        Component = (
          <DateRangeFilter
            filter={selectedFilter as IFilterDateRange}
            value={values?.[selectedFilter.id]}
            onChange={(v, close) => onChange(selectedFilter.id, v, close)}
          />
        )
        break

      case TableFilterType.GroupedList:
        Component = (
          <GroupedListFilter
            filter={selectedFilter as IFilterGroupedList}
            selected={values?.[selectedFilter.id]}
            onChange={(v) => onChange(selectedFilter.id, v)}
          />
        )
        break

      case TableFilterType.List: {
        Component = (
          <ListFilter
            filter={selectedFilter as IFilterList}
            selected={values?.[selectedFilter.id]}
            onChange={(v) => onChange(selectedFilter.id, v)}
          />
        )
        break
      }

      default: {
        break
      }
    }

    return (
      <Slide direction="left" in={!!selectedFilter} mountOnEnter unmountOnExit>
        <div>{Component}</div>
      </Slide>
    )
  }

  return (
    <>
      {isFilterApplied && (
        <Portal wrapperId="filters-portal" disabled={!isSmallView}>
          <div
            className={clsx(
              'tw-self-center',
              isSmallView && 'tw-mx-16 tw-mt-8'
            )}
          >
            <FilterValues
              onChange={onChange}
              filters={filters as IFilterItem[]}
              values={values}
              toggleSpecificFilter={toggleSpecificFilter}
            />
          </div>
        </Portal>
      )}

      <Row items="stretch" gap={8} className="tw-self-stretch">
        <Button
          keepFocus={open}
          type="outlined"
          onClick={handleClick}
          aria-describedby="filter-popover"
          startIcon={!isFilterApplied && <FilterListIcon />}
        >
          {isFilterApplied ? <FilterListIcon /> : 'Filter'}
        </Button>

        {isFilterApplied && (
          <Button type="text" onClick={clearFilters}>
            Clear
          </Button>
        )}
      </Row>

      <Popover
        disablePortal
        id="filter-popover"
        open={open}
        anchorEl={containerRef.current}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        classes={{ paper: styles.filterDropdown }}
      >
        {selectedFilter ? renderSecondStep() : FirstStep}
      </Popover>
    </>
  )
}

export default FilterDropdown
