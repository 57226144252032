import { IFilterItem, IFilterValues } from '@/common/types'
import { TableFilterType } from '@/common/enums'
import dayjs from 'dayjs'
import 'dayjs/plugin/isBetween'

const filter = (filters: IFilterItem[], values: IFilterValues, rows: any[]) => {
  if (!rows?.length) return []

  const isFilterApplied = Object.values(values || {}).some(
    (filterValue) => !!filterValue
  )

  if (!isFilterApplied) return rows

  return rows.filter((row) => {
    let valid = true

    Object.entries(values).forEach(([key, value]) => {
      if (!value || !valid) return

      const currentFilter = filters.find((filter) => filter.id === key)

      if (!currentFilter) return

      switch (currentFilter.type) {
        case TableFilterType.List: {
          const found = row[currentFilter.id]?.some?.((item: any) =>
            value.includes(item.id)
          )

          if (!found) {
            valid = false
          }

          break
        }

        case TableFilterType.GroupedList: {
          let tempValid = false

          Object.entries(value).forEach(([groupKey, filterValue]) => {
            if (!filterValue) return

            const found = row[groupKey]?.some?.((item: any) =>
              (filterValue as string[]).includes(item)
            )

            if (found) {
              tempValid = true
            }
          })

          valid = tempValid
          break
        }

        case TableFilterType.DateRange: {
          if (typeof value === 'string') {
            valid = !!currentFilter.extraOptions
              ?.find((option) => option.id === value)
              ?.isValid(row)

            return
          }

          if (!row[key]) {
            valid = false
            return
          }

          if (value.startDate && value.endDate) {
            valid = dayjs(row[key]).isBetween(
              value.startDate.startOf('day'),
              value.endDate.endOf('date')
            )

            return
          }

          valid = value.startDate
            ? dayjs(row[key]).isAfter(value.startDate.startOf('day'))
            : dayjs(row[key]).isBefore(value.endDate.endOf('day'))
          break
        }

        default: {
          break
        }
      }
    })

    return valid
  })
}

export default filter
