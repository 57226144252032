import { items } from '@/common/components/organisms/Navigation/navigationItems'

const getPageTitle = (pathname: string) => {
  let title = ''

  // Search in main items
  items.some((item) => {
    if (item.link === pathname) {
      title = item.title
      return true
    }

    // Search in sub items
    if (Array.isArray(item.items)) {
      return item.items.some((subItem) => {
        if (subItem.link === pathname) {
          title = subItem.title
          return true
        }

        return false
      })
    }

    return false
  })

  return title
}

export default getPageTitle
