export enum TextTypes {
  DISPLAY_XL = 'display-xl',
  DISPLAY_LG = 'display-lg',
  DISPLAY_MD = 'display-md',
  DISPLAY_SM = 'display-sm',
  DISPLAY_XS = 'display-xs',
  TEXT_XL = 'text-xl',
  TEXT_LG = 'text-lg',
  TEXT_MD = 'text-md',
  TEXT_SM = 'text-sm',
  TEXT_XS = 'text-xs'
}

export enum HeadingTypes {
  H1 = 'h1',
  H2 = 'h2',
  H3 = 'h3',
  H4 = 'h4',
  H5 = 'h5',
  H6 = 'h6'
}

export enum FontWeight {
  REGULAR = 'font-regular',
  SEMIBOLD = 'font-semibold',
  BOLD = 'font-bold',
  BLACK = 'font-black'
}

export enum TextAlign {
  LEFT = 'left',
  CENTER = 'center',
  RIGHT = 'right',
  JUSTIFY = 'justify'
}
