import clsx from 'clsx'
import React, {
  CSSProperties,
  FC,
  ForwardedRef,
  forwardRef,
  ForwardRefRenderFunction,
  KeyboardEvent,
  PropsWithChildren,
  ReactNode
} from 'react'

import { IFlexProps } from '../types'
import styles from './Row.module.scss'

interface IRowProps extends Omit<IFlexProps, 'direction'> {
  children?: ReactNode
  className?: string
  id?: string
  tabIndex?: number
  onKeyDown?: (event: KeyboardEvent) => void
}

const Row: ForwardRefRenderFunction<
  HTMLDivElement,
  PropsWithChildren<IRowProps>
> = (
  {
    justify = 'start',
    items = 'start',
    reverse = false,
    gap = 0,
    id,

    clickable,
    className,
    inline,
    onClick,
    tabIndex,
    children,
    onKeyDown
  }: IRowProps,
  ref
) => (
  <div
    ref={ref}
    onKeyDown={onKeyDown}
    id={id}
    tabIndex={tabIndex}
    style={{ '--gap': `${gap}px` } as CSSProperties}
    className={clsx(
      styles.row,
      inline && styles.inline,
      styles[`justify-${justify}`],
      styles[`align-${items}`],
      reverse && styles.reverse,
      clickable && 'tw-cursor-pointer',
      className
    )}
    onClick={onClick}
  >
    {children}
  </div>
)

export default forwardRef(Row)
