import React, {
  FC,
  PropsWithChildren,
  useState,
  useContext,
  useMemo
} from 'react'

interface MobileNavigationContextValue {
  toggleNavigation: (value?: boolean) => void
  navigationOpened: boolean
}

const MobileNavigationContext =
  React.createContext<MobileNavigationContextValue>(
    {} as MobileNavigationContextValue
  )

const MobileNavigationContextProvider: FC<PropsWithChildren<any>> = (props) => {
  const { children } = props

  const [navigationOpened, setNavigationOpened] = useState(false)

  const toggleNavigation = (value?: boolean) => {
    if (typeof value === 'boolean') {
      setNavigationOpened(value)
      return
    }

    setNavigationOpened(!navigationOpened)
  }

  const providerValue = useMemo(
    () => ({
      toggleNavigation,
      navigationOpened
    }),
    [navigationOpened, toggleNavigation]
  )

  return (
    <MobileNavigationContext.Provider value={providerValue}>
      {children}
    </MobileNavigationContext.Provider>
  )
}

const useMobileNavigationContext = () => useContext(MobileNavigationContext)

export { MobileNavigationContextProvider, useMobileNavigationContext }
