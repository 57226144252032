import clsx from 'clsx'
import React, { FC, PropsWithChildren, ReactNode } from 'react'

import { FontWeight, TextAlign, TextTypes } from '../enums'
import styles from './Text.module.scss'

export interface ITextProps {
  type?: TextTypes
  weight?: FontWeight
  color?: string | string[]
  align?: TextAlign
  children?: ReactNode
  className?: string
  onClick?: () => void
}

const Text: FC<PropsWithChildren<ITextProps>> = ({
  type = TextTypes.TEXT_MD,
  weight = FontWeight.REGULAR,
  color,
  align = TextAlign.LEFT,
  className,
  children,
  onClick
}: ITextProps) => {
  const baseClasses = [
    styles.inlineBlock,
    styles[type],
    weight,
    `text-${align}`,
    className
  ]

  if (color && Array.isArray(color)) {
    baseClasses.unshift(...color)
  } else if (color) {
    baseClasses.unshift(color)
  }

  return (
    <p onClick={onClick} className={clsx(baseClasses)}>
      {children}
    </p>
  )
}

export default Text
