import { IAvailableSite, ITableColumn } from '@/common/types'
import { TableActions, TableColumnType } from '@/common/enums'
import { roleNamesMapper } from '@/common/utils'

const groupTitleMapper: { [key: string]: string } = {
  portal: 'Portal',
  site: 'Site',
  role: 'Role'
}

const userManagementColumns = (groupBy: string | undefined): ITableColumn[] => [
  ...(groupBy
    ? [
        {
          id: 'title',
          title: groupTitleMapper[groupBy] || 'Group',
          type: TableColumnType.Text,
          className: 'color-green500'
        }
      ]
    : []),
  {
    id: 'name',
    title: 'Name',
    type: TableColumnType.Text,
    className: groupBy ? undefined : 'color-green500 !tw-opacity-100'
  },
  {
    id: 'lastActivity',
    title: 'Last Active',
    type: TableColumnType.Date
  },
  {
    id: 'enterpriseRoles',
    title: 'Enterprise Role',
    type: TableColumnType.Text,
    converter: ({ enterpriseRoles }: any) => roleNamesMapper(enterpriseRoles)
  },
  {
    id: 'siteRoles',
    title: 'Site Role',
    type: TableColumnType.Text,
    converter: ({ siteRoles }: any) => roleNamesMapper(siteRoles)
  },
  {
    id: 'availableSites',
    title: 'Site Access',
    type: TableColumnType.Text,
    converter: ({ availableSites }: any) =>
      availableSites.length === 3
        ? 'All'
        : availableSites.map((site: IAvailableSite) => site.name).join(', ')
  },
  {
    id: 'actions',
    title: '',
    type: TableColumnType.Actions,
    className: 'tw-w-55',
    actions: [
      { id: TableActions.Edit, label: 'Edit' },
      { id: TableActions.Delete, label: 'Delete' }
    ]
  }
]

export default userManagementColumns
