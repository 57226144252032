import { IUser } from '@/common/types'
import { EnterpriseRoles, SiteRoles } from '@/common/enums'

interface IGroupedItem {
  id: string
  title: string
  rows: IUser[]
}

interface IPortalGrouped {
  enterprise: IUser[]
  site: IUser[]
}

const roleNameMapper: { [key in SiteRoles | EnterpriseRoles]: string } = {
  [SiteRoles.Admin]: 'Site Admin',
  [SiteRoles.Gate]: 'Gate',
  [SiteRoles.ViewOnly]: 'View Only',
  [SiteRoles.ISRManager]: 'Site ISR Manager',
  [EnterpriseRoles.Admin]: 'Enterprise Admin',
  [EnterpriseRoles.ISRManager]: 'Enterprise ISR Manager'
}

const groupingHelper = (groupBy: string, rows: IUser[]) => {
  if (rows.length === 0) return []

  switch (groupBy) {
    case 'portal': {
      const groupedRows = rows.reduce(
        (acc: IPortalGrouped, next: IUser) => {
          if (next.enterpriseRoles.length) {
            acc.enterprise.push(next)
          }

          if (next.siteRoles.length) {
            acc.site.push(next)
          }

          return acc
        },
        { enterprise: [], site: [] }
      )

      const result = []

      if (groupedRows.enterprise.length) {
        result.push({
          id: 'enterprise',
          title: 'Enterprise',
          rows: groupedRows.enterprise
        })
      }

      if (groupedRows.site.length) {
        result.push({
          id: 'site',
          title: 'Site',
          rows: groupedRows.site
        })
      }

      return result
    }

    case 'role': {
      const groupedRows = rows.reduce((acc: { [key: string]: any[] }, next) => {
        next.siteRoles.forEach((role) => {
          if (Array.isArray(acc[role])) {
            acc[role].push(next)
          } else {
            acc[role] = [next]
          }
        })

        next.enterpriseRoles.forEach((role) => {
          if (Array.isArray(acc[role])) {
            acc[role].push(next)
          } else {
            acc[role] = [next]
          }
        })

        return acc
      }, {})

      return Object.entries(groupedRows).map(([key, value]) => ({
        id: key,
        title: roleNameMapper[key as EnterpriseRoles],
        rows: value
      }))
    }

    case 'site': {
      const names: { [key: string]: string } = {}

      const groupedRows = rows.reduce((acc: { [key: string]: any[] }, next) => {
        next?.availableSites?.forEach?.((site) => {
          if (Array.isArray(acc[site.id])) {
            acc[site.id].push(next)
          } else {
            acc[site.id] = [next]
            names[site.id] = site.name
          }
        })

        return acc
      }, {})

      return Object.entries(groupedRows).map(([key, value]) => ({
        id: key,
        title: names[key],
        rows: value
      }))
    }

    default: {
      return rows
    }
  }
}

export default groupingHelper
