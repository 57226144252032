import { EnterpriseRoles, SiteRoles } from '@/common/enums'

const roleLists = {
  enterprise: [
    { id: EnterpriseRoles.Admin, label: 'Admin' },
    { id: EnterpriseRoles.ISRManager, label: 'ISR Manager' }
  ],
  site: [
    { id: SiteRoles.Admin, label: 'Admin' },
    { id: SiteRoles.ISRManager, label: 'ISR Manager' },
    { id: SiteRoles.Gate, label: 'Gate' },
    { id: SiteRoles.ViewOnly, label: 'View Only' }
  ]
}

export default roleLists
