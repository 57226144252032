import { HeaderWithFooterTemplate } from '@/common/templates'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import {
  Button,
  Col,
  FontWeight,
  Input,
  Row,
  Text,
  TextTypes
} from '@/common/components/atoms'
import { Color } from '@/packages/palette'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import schema from './schema'
import { useUserContext } from '@/common/contexts/UserContext'

import styles from './ProfileTemplate.module.scss'
import { useState } from 'react'
import { Slide, Snackbar } from '@mui/material'

const ProfileTemplate = () => {
  const { user } = useUserContext()

  const [saved, setSaved] = useState(false)

  const { name, email } = user
  const {
    watch,
    trigger,
    register,
    setValue,
    clearErrors,
    formState: { errors, isValid, isDirty }
  } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
    mode: 'onChange',
    defaultValues: {
      firstName: name?.split?.(' ')[0],
      lastName: name?.split?.(' ')[1],
      email
    }
  })

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return
    }

    setSaved(false)
  }

  return (
    <HeaderWithFooterTemplate withoutPortalDropdown title="Account">
      <Col gap={16} className={styles.profileWrapper}>
        <Text
          type={TextTypes.TEXT_MD}
          weight={FontWeight.SEMIBOLD}
          color={Color.gray700}
        >
          Your Profile
        </Text>

        <Row gap={16} className={styles.mainInfoHolder}>
          <Input
            {...register('firstName')}
            name="firstName"
            error={!!errors?.firstName}
            helperText={errors?.firstName?.message}
            fullWidth
            label="First Name"
            required
          />
          <Input
            {...register('lastName')}
            name="lastName"
            error={!!errors?.lastName}
            helperText={errors?.lastName?.message}
            fullWidth
            label="Last Name"
            required
          />
        </Row>

        <Input
          {...register('email')}
          name="email"
          error={!!errors?.email}
          helperText={errors?.email?.message}
          label="Email"
          type="email"
          required
        />

        <Button className="tw-self-start" type="outlined">
          Change Password
        </Button>
        <Button
          className="tw-self-start"
          type="primary"
          disabled={!isDirty || !isValid}
          onClick={() => setSaved(true)}
        >
          Save Changes
        </Button>
      </Col>

      <Snackbar
        open={saved}
        autoHideDuration={3000}
        TransitionComponent={(props) => <Slide {...props} direction="up" />}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <div className={styles.snackbar}>
          <Row items="center" gap={8}>
            <CheckCircleOutlineIcon />
            <Text type={TextTypes.TEXT_SM} color={Color.gray700}>
              Your changes have been saved.
            </Text>
          </Row>
        </div>
      </Snackbar>
    </HeaderWithFooterTemplate>
  )
}

export default ProfileTemplate
