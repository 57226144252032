import React, { FC, useMemo, useState } from 'react'
import { TableCell, TableRow } from '@mui/material'
import { ITableActionColumn, ITableColumn } from '@/common/types'
import {
  CollapseArrow,
  FontWeight,
  Row,
  Text,
  TextTypes
} from '@/common/components/atoms'
import { ActionsDropdown } from '@/common/components/molecules'
import { TableActions, TableColumnType } from '@/common/enums'

import { useTableContext } from '@/common/contexts/TableContext'

import styles from './TableRow.module.scss'
import dayjs from 'dayjs'
import clsx from 'clsx'

interface IProps {
  clickable?: boolean
  columns: ITableColumn[]
  row: any
  idAccessor: string
}

const CustomTableRow: FC<IProps> = (props) => {
  const { clickable, columns, row, idAccessor } = props
  const { groupBy, handleAction, isRowInactive } = useTableContext()

  const inactive = useMemo(() => isRowInactive?.(row), [])

  const { rows: innerRows } = row

  const [open, setOpen] = useState(false)
  const showInnerRows = !!groupBy && open && !!innerRows?.length

  const renderCell = (
    row: any,
    column: ITableColumn,
    index: number,
    innerRow?: boolean
  ) => {
    if (groupBy && !innerRow)
      return (
        index === 0 && (
          <Row items="center" gap={8}>
            <CollapseArrow visible active={open} />

            <Text type={TextTypes.TEXT_SM} className="tw-whitespace-nowrap">
              {row.title}
            </Text>
            <Text
              type={TextTypes.TEXT_MD}
              weight={FontWeight.SEMIBOLD}
              className={styles.groupRowsNumber}
            >
              {innerRows?.length || 0}
            </Text>
          </Row>
        )
      )

    const value =
      typeof column.converter === 'function'
        ? column.converter(row)
        : row[column.id]

    switch (column.type) {
      case TableColumnType.Date: {
        return (
          <Text type={TextTypes.TEXT_SM} className="tw-whitespace-nowrap">
            {value ? dayjs(value).format('MM/DD/YYYY, HH:mm:ss') : '-'}
          </Text>
        )
      }

      case TableColumnType.Actions: {
        const columnWithActions = column as ITableActionColumn

        const onActionClick = (action: string) => {
          handleAction?.(action as TableActions, row)
        }

        return (
          <ActionsDropdown
            actions={columnWithActions.actions}
            onClick={onActionClick}
          />
        )
      }

      default: {
        return (
          <Text type={TextTypes.TEXT_SM} className="tw-whitespace-nowrap">
            {value}
          </Text>
        )
      }
    }
  }

  const onRowClick = (row: any, groupHeader?: boolean) => {
    if (!!groupBy && groupHeader) {
      setOpen(!open)
      return
    }

    handleAction?.(TableActions.RowClick, row)
  }

  return (
    <>
      <TableRow
        hover={clickable}
        tabIndex={-1}
        onClick={(e) => {
          e.stopPropagation()
          onRowClick(row, true)
        }}
      >
        {columns.map((column, index) => (
          <TableCell
            key={`${row[idAccessor]}-${column.id}`}
            variant="body"
            align="left"
            className={clsx(
              column.className,
              inactive &&
                column.type !== TableColumnType.Actions &&
                styles.inactive
            )}
          >
            {renderCell(row, column, index)}
          </TableCell>
        ))}
      </TableRow>

      {showInnerRows &&
        innerRows.map((innerRow: any) => (
          <TableRow key={innerRow[idAccessor]} hover={clickable} tabIndex={-1}>
            {columns.map((column, index) => (
              <TableCell
                key={`${row.id}-${innerRow[idAccessor]}-${column.id}`}
                variant="body"
                align="left"
                className={column.className}
              >
                {renderCell(innerRow, column, index, true)}
              </TableCell>
            ))}
          </TableRow>
        ))}
    </>
  )
}

export default CustomTableRow
