import React, {
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState
} from 'react'

import { Brands, EnterpriseRoles, PortalTypes, SiteRoles } from '@/common/enums'
import { IAvailablePortals, IUser } from '@/common/types'

interface UserContextValue {
  user: IUser
  portalType: PortalTypes
  role: SiteRoles | EnterpriseRoles
  brand: Brands
  availablePortals: IAvailablePortals
  updateBrand: (brand: Brands) => void
  updateRole: (role: SiteRoles | EnterpriseRoles) => void
  updatePortalType: (portalType: PortalTypes) => void
}

const UserContext = React.createContext<UserContextValue>(
  {} as UserContextValue
)

const UserContextProvider: FC<PropsWithChildren<any>> = (props) => {
  const { children } = props

  const [values, setValues] = useState<
    Omit<UserContextValue, 'updateBrand' | 'updateRole' | 'updatePortalType'>
  >({
    user: {
      id: '1',
      enterpriseRoles: [],
      siteRoles: [],
      lastActivity: undefined,
      name: 'Misha Kliuvak',
      email: 'mishakliuvak10@gmail.com'
    },
    portalType: PortalTypes.Site,
    role: SiteRoles.Admin,
    brand: Brands.Terminal,
    availablePortals: {
      enterprisePortal: true,
      sitePortals: [
        { name: 'Abilene', id: 'abilene' },
        { name: 'Dallas', id: 'dallas' }
      ]
    }
  })

  const updateBrand = useCallback(
    (brand: Brands) => {
      setValues((prev) => ({ ...prev, brand }))
    },
    [setValues]
  )

  const updatePortalType = useCallback(
    (portalType: PortalTypes) => {
      setValues((prev) => ({
        ...prev,
        portalType,
        role:
          portalType === PortalTypes.Site
            ? SiteRoles.Admin
            : EnterpriseRoles.Admin
      }))
    },
    [setValues]
  )

  const updateRole = useCallback(
    (role: SiteRoles | EnterpriseRoles) => {
      setValues((prev) => ({
        ...prev,
        role
      }))
    },
    [setValues]
  )

  const providerValue = useMemo(
    () => ({
      ...values,
      updateRole,
      updatePortalType,
      updateBrand
    }),
    [values]
  )

  return (
    <UserContext.Provider value={providerValue}>
      {children}
    </UserContext.Provider>
  )
}

const useUserContext = () => useContext(UserContext)

export { UserContextProvider, useUserContext }
